@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./assets/scss/fonts.scss";

body {
  overflow-x: hidden;
  font-family: "Volvo Novum", "Noto Sans";
}

/* custom styling to input type number for removing arrows */
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.embed__container {
  position: relative;
  display: block !important;
}

.cmp-embed {
  margin: 0 !important;
}

.root.responsivegrid {
  max-width: 100% !important;
}

.footer__container {
  visibility: hidden !important;
}

.ot-floating-button__open svg {
  display: inline !important;
}

.hidden-vtg {
  @apply hidden;
}

#ot-sdk-btn-floating.ot-floating-button {
  bottom: 190px !important;
  width: 45px !important;
  height: 45px !important;
}

#volvoTrucksConfiguratorApp {
  display: block;
  overflow-x: hidden !important;
}

#color-scrollbar {
  margin-right: calc(-1 * (100vw - 100%));
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px     rgba(0,0,0,0.3);
  background: #fffdff;
}

::-webkit-scrollbar-track:hover {
  -webkit-box-shadow: inset 0 0 6px     rgba(0,0,0,0.3);
  background: #f1f0f1;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px     rgba(0,0,0,0.3);
  background: #c9c8ca;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  -webkit-box-shadow: inset 0 0 6px     rgba(0,0,0,0.3);
  background: #868486;
  border-radius: 20px;
}