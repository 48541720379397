.turntable {
  flex-grow: 1;
  min-width: 0px;
  min-height: 0px;
  width: 100vw;
  height: 100vh;
  touch-action: none;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  canvas {
    // position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}