@font-face {
  font-family: 'Volvo Novum Light';
  font-display: swap;
  font-weight: 300;
  src: url('../fonts/VolvoNovum/VolvoNovum-Light.woff') format('woff'),
  url('../fonts/VolvoNovum/VolvoNovum-Light.woff') format('woff');
}
@font-face {
  font-family: 'Volvo Novum Light Italic';
  font-display: swap;
  font-weight: 300;
  src: url('../fonts/VolvoNovum/VolvoNovum-LightItalic.woff') format('woff'),
  url('../fonts/VolvoNovum/VolvoNovum-LightItalic.woff') format('woff');
}
@font-face {
  font-family: 'Volvo Novum';
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/VolvoNovum/VolvoNovum-Regular.woff') format('woff'),
  url('../fonts/VolvoNovum/VolvoNovum-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Volvo Novum Italic';
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/VolvoNovum/VolvoNovum-Italic.woff') format('woff'),
  url('../fonts/VolvoNovum/VolvoNovum-Italic.woff') format('woff');
}
@font-face {
  font-family: 'Volvo Novum Medium';
  font-display: swap;
  font-weight: 500;
  src: url('../fonts/VolvoNovum/VolvoNovum-Medium.woff') format('woff'),
  url('../fonts/VolvoNovum/VolvoNovum-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Volvo Novum Medium Italic';
  font-display: swap;
  font-weight: 500;
  src: url('../fonts/VolvoNovum/VolvoNovum-MediumItalic.woff') format('woff'),
  url('../fonts/VolvoNovum/VolvoNovum-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Noto Sans';
  font-display: swap;
  src: url('../fonts/NotoSans/NotoSans-Regular.ttf') format('truetype'),
  url('../fonts/NotoSans/NotoSans-Regular.ttf') format('truetype')
}
@font-face {
  font-family: 'Noto Sans Medium';
  font-display: swap;
  src: url('../fonts/NotoSans/NotoSans-Medium.ttf') format('truetype'),
  url('../fonts/NotoSans/NotoSans-Medium.ttf') format('truetype')
}
